<template>
  <div>
    <input type="range" min="0" :max="duration" v-model="currentTime" @input="seek">
    <span>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
    <el-button type="primary" plain @click="togglePlay" style="width: 100%;">{{code}}</el-button>
    <el-row :gutter="20">
      <el-col :span="6"> <el-button type="succeed" plain @click="increaseRate(1)">x1倍</el-button></el-col>
      <el-col :span="6"> <el-button type="succeed" plain @click="increaseRate(1.25)">x1.25倍</el-button></el-col>
      <el-col :span="6"> <el-button type="succeed" plain @click="increaseRate(1.5)">x1.5倍</el-button></el-col>
      <el-col :span="6"> <el-button type="succeed" plain @click="increaseRate(2)">x2倍</el-button></el-col>
    <!-- <el-col :span="6"><el-text>当前倍数:x{{ rate }}</el-text></el-col> -->
  </el-row>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount,onBeforeMount,onUpdated} from 'vue';
import { Howl } from 'howler';
import { useRouter } from 'vue-router'

export default {

  setup() {
    const test = localStorage.getItem("play2")
    const router = useRouter();
    const rate = ref(1);
    const code = ref("播放")
    const sound = ref(0);
    const currentTime = ref(0);
    const duration = ref(0);
    const isPlaying = ref(false);
    const get_auto = localStorage.getItem("audioCurrentTime")?JSON.parse(localStorage.getItem("audioCurrentTime")):{}
    var id = router.currentRoute.value.query['id']

    if(id){ //如果存在这个id
      localStorage.setItem("vid",id)
    }else{
      id=localStorage.getItem("vid")
    }
    const refesh = localStorage.getItem("playerRefesh")?0:1
        localStorage.setItem("queryid",id)
      try{
        currentTime.value=get_auto[id]?Number(get_auto[id]):0
      }catch{

      }
      if(refesh===1){  // !get_auto[id] && 
        location.reload();
        localStorage.setItem("playerRefesh",0)
      }else{
        sound.value = new Howl({
        src: [
          localStorage.getItem("play2")
        ],      
        preload: true,
        html5: true, // 强制使用 HTML5 音频
        autoplay: false,
        onplay: function() {
        requestAnimationFrame(updateProgress);
        }
        })
        try{  
          if(get_auto[id]){
            code.value='继续播放'
          }
        }catch{
          
        }
      }
    const increaseRate = (r) => {
        rate.value =r
        sound.value.rate(rate.value);
        };
    // 更新进度条
    const updateProgress = () => {
      if (sound.value && sound.value.playing() && localStorage.getItem('vid')) {
        currentTime.value = sound.value.seek();  // 更新的时间 放到缓存中
        duration.value = sound.value.duration();
        requestAnimationFrame(updateProgress);
        if((duration.value-currentTime.value)<=1){
          // 如果结束了，就不需要这个vid参数了
          localStorage.removeItem('vid')
        }
        try{
          if(!get_auto[id]){
            get_auto[id]=0.1
            localStorage.setItem("audioCurrentTime", JSON.stringify(get_auto));
          }else{
              get_auto[id]=sound.value.seek();
              localStorage.setItem("audioCurrentTime", JSON.stringify(get_auto));
          }
        }
        catch{
        }
      }
    };

    // 跳转到指定时间
    const seek = () => {
      try{
          get_auto[id]=sound.value.seek();
        }
        catch{
        }
        localStorage.setItem("audioCurrentTime", JSON.stringify(get_auto));
        sound.value.seek(currentTime.value);
    };

    // 切换播放状态
    const togglePlay = () => {
      if (sound.value && sound.value.playing()) {
            sound.value.pause();
            code.value='播放'

      } else {
        seek()
        sound.value.rate(rate.value);
        sound.value.play();
        code.value='暂停'

      }
      isPlaying.value = sound.value.playing();
    };

    // 格式化时间
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      if(!minutes&&!seconds){
        return '00:00'
      }
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    const beforeUnmountHandler = () => {
      localStorage.removeItem("playerRefesh")  // 退出页面后，移除更新参数
      sound.value.pause();  // 跳出页面结束播放
      // 在组件即将销毁之前执行的操作
      // togglePlay()
      // 可以在这里进行清理工作或其他操作
    };
    onBeforeUnmount(beforeUnmountHandler);
    
    return {
      currentTime,
      duration,
      isPlaying,
      togglePlay,
      seek,
      formatTime,
      get_auto,
      id,
      increaseRate,
      rate,
      code,
      beforeUnmount: beforeUnmountHandler,
      sound,
      test
    };
  }
};
</script>
