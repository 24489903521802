<template>
  <payer></payer>
  <div :style="{'height':screenHeight+'px','width':'100%'}" >
      <div id="tcts">
        再次滑动程序退出
      </div>
      <div id="content" :style="{'height':screenHeight-bottom+'px'}">
        <router-view></router-view>
      </div>
      <div id="bottom" :style="{'height':bottom+'px','width':'100%','z-index':7}">
          <bottomapp :height='bottom'></bottomapp>
      </div>
  </div>
</template>
<style scoped>
    #tcts{
      width:150px;
      height:30px;
      position:absolute;
      top:50%;
      left:30%;
      z-index: 7;
      background:black;
      color:white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 13px;
      opacity: 0;
    }
</style>
<style scoped src='../public/static/css/app.css'></style>
<script>
import bottomapp from '@/components/bottom.vue'
import payer from '@/other-tool/aPayerMin.vue'
// import top from '@/components/top.vue'
import wx from "weixin-js-sdk";
import { ElMessage, ElMessageBox } from 'element-plus'
import secray from '@/tool/secray.js'
import axios from 'axios';
export default {
  components:{
    bottomapp,
    top,
    payer
  },
  data(){
    return {
      screenHeight:0,
      bottom:50,
      top:50,

    }
  },
  methods:{
    handleResize() {
      // 窗口大小改变时，重新计算屏幕高度
      this.screenHeight = window.innerHeight
    },
    wxShare () {
      // alert(window.location.href.split("#")[0])
      var url = window.location.href//.split("#")[0];
      // var url = encodeURIComponent(window.location.href.split("#")[0]);
      axios.get(
        window.g.fwd+'/wxapi/share/wx/',
          {params:{url:url}}
        )
        .then((res) => {
          let data = res['data']['data'];
          wx.config({
            debug: false,
            appId: 'wxef438bd24e3e33ee',
            timestamp : data.timestamp,
            nonceStr : data.nonceStr,
            signature : data.signature,
            jsApiList: ["onMenuShareTimeline"
        ,"onMenuShareAppMessage"
        ,"onMenuShareQQ",
          'onMenuShareWeibo',
        'onMenuShareQZone',
      'updateAppMessageShareData',
    'updateTimelineShareData'] // 必填，需要使用的JS接口列表
          });
        });
      wx.ready(() => {
        var shareData = {
          title: "标题...",
          desc: "描述...",
          link: "http://cqzy.zhongblock.com/ ",
          imgUrl:"http://cqzy.zhongblock.com/static/image/icon/up.png",  
          success: () => { 
            // alert("成功")
          },
          type: 'link',

        };
        wx.onMenuShareTimeline(shareData);
        wx.onMenuShareAppMessage(shareData);
        wx.onMenuShareQQ(shareData);
        wx.onMenuShareWeibo(shareData);
        wx.onMenuShareQZone(shareData);
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
      });
      wx.error((res)=>{
        // alert(res.errMsg)
      })
    }
    
},
  created(){
  },
  mounted(){
    // 在组件挂载后添加事件监听器
    window.addEventListener('resize', this.handleResize);
    // 计算初始屏幕高度
    this.screenHeight = window.innerHeight;
    localStorage.setItem("height",this.screenHeight-this.bottom)
    localStorage.setItem("bottom",this.bottom)
    this.wxShare()
  },
}
</script>
