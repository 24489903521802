<template> 
    <el-radio-group v-model="tags" size="2000"  style="width:100%"  fill="rgba(0,0,0,0.1)" text-color="black">
        <el-radio-button label="行情" value="main" style="width:25%;height:100%" @click="toLink({'name':'main'})"/>
        <el-radio-button label="资讯" value="search" style="width:25%" @click="toLink({'name':'search'})" />
        <el-radio-button label="数据" value='data' style="width:25%" @click="toLink({'name':'data'})" />
        <el-radio-button label="我的" value="my" style="width:25%;"  @click="toLink({'name':'my'})" />
    </el-radio-group>
</template>


<script>
import router from '../router'
export default {
    props:{code:{default:''}},
    name:"bottom",
    data(){
        return {
            tags:this.color()//localStorage.getItem("homecode")?localStorage.getItem("homecode"):"行情",
        }
    },
    methods:{
        color(){
            this.tags = localStorage.getItem("homecode")
            return this.tags
        }
        ,toLink(to){
            this.$router.push(to)
        }
    },
    updated(){
    },
    created(){
    },
    mounted() {
        this.color()
        setTimeout(() => {
            this.color()
        }, 500);

    // 在 mounted 钩子中访问路由元信息
  },
}
</script>

<style scoped src='../../public/static/css/app.css'></style>
