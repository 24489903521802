import { createStore } from 'vuex'

export default createStore({
  state: {
    NewCode:'项目新闻',
    DataCode:"气泡图"
  },
  getters: {
      getNewCode(state){
        return state.NewCode
      },
      getDataCode(state){
        return state.DataCode
      }
  },
  mutations: {  // 增删改查
    setNewCode(state,code='项目新闻'){
      state.NewCode=code
    },
    setDataCode(state,code='气泡图'){
      state.DataCode=code
    },
  },
  actions: {
  },
  modules: {
  }
})
